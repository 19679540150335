<template>
  <div>
    <vx-card>
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="start"
          vs-align="center"
          vs-lg="2"
          vs-md="2"
          vs-sm="2"
          vs-xs="6"
          >วันที่ลงรายการ</vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="4"
          vs-sm="4"
          vs-xs="6"
        >
          <div
            class="grid-layout-container alignment-block"
            style="vertical-align:text-top ;width:100%;"
          >
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <v-date-picker
                  class="mobile-order-custom-date-picker"
                  :locale="{
                    id: 'th',
                    firstDayOfWeek: 2,
                    masks: { L: 'DD/MM/YYYY' }
                  }"
                  mode="range"
                  v-model="calendarData"
                />
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-w="12"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="3"
              vs-sm="3"
              vs-w="6"
              class="px-2"
            >
              <vs-button
                @click="searchBtn()"
                color="primary"
                type="filled"
                class="w-full"
              >
                ค้นหา
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="3"
              vs-sm="3"
              vs-w="6"
              class="px-2"
            >
              <vs-button
                @click="resetBtn()"
                color="warning"
                type="filled"
                class="w-full"
              >
                รีเซ็ต
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-w="4"
              class="px-2"
            >
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <vs-table
        pagination
        max-items="20"
        search
        :data="orders"
        class="mobile-order-table"
      >
        <template slot="thead" class="mobile-order-table-th">
          <vs-th>ลงรายการ</vs-th>
          <vs-th>เลขออเดอร์</vs-th>
          <vs-th>ข้อมูลชื่อผู้เช่า</vs-th>
          <vs-th>ยอดเช่าชุด</vs-th>
          <vs-th>เงินประกัน</vs-th>
          <vs-th>วันรับชุด</vs-th>
          <vs-th>วันคืนชุด</vs-th>
          <vs-th>การจัดการ</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr v-for="(tr, index) in data" :key="`order-${index}`" :data="tr">
            <vs-td>
              {{ $dateFormat(tr.createDateTime) }}<br />
              เวลา {{ $dateFormatTime(tr.createDateTime) }}
            </vs-td>
            <vs-td>
              {{ tr.wknOrderNumber }}
              <!-- <br /> -->
              <!-- <span class="mt-4">{{ tr.branch }}</span> -->
              <br />
              <vs-button
                color=""
                style="background-color: #8D6E63 !important; color: #ffffff"
                class="rounded-full mt-2"
                >ลูกค้าหน้าร้าน</vs-button
              >
            </vs-td>
            <vs-td>
              <span v-if="tr.customerName !== null">
                {{ tr.customerName }}<br />
                <!-- {{ tr.info.address }}<br /> -->
                {{ tr.customerPhone }}
              </span>
              <span class="text-danger" v-else>
                ไม่มีชื่อในระบบ<br />
                {{ tr.customerPhone }}
              </span>
            </vs-td>
            <vs-td>{{
              tr.rentalPriceTotal.toLocaleString("en-US", localeStringConfig)
            }}</vs-td>
            <vs-td>{{
              tr.bailTotal.toLocaleString("en-US", localeStringConfig)
            }}</vs-td>
            <vs-td>{{ $dateFormat(tr.startDate) }}</vs-td>
            <vs-td>{{ $dateFormat(tr.endDate) }}</vs-td>
            <vs-td>
              <vs-dropdown>
                <a href="#">
                  <div
                    class="px-4 py-2 rounded"
                    style="background-color: #f2f2f2"
                  >
                    <font-awesome-icon
                      icon="ellipsis-h"
                      style="cursor: pointer; color: gray"
                    />
                  </div>
                </a>

                <vs-dropdown-menu>
                  <vs-dropdown-item>
                    พิมพ์
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    divider
                    @click="fetchOrderDetail(tr.wknOrderId)"
                  >
                    เปลี่ยนออเดอร์<br />เป็นรอรับชุด
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="removeOrder(tr.wknOrderId)" divider>
                    ลบ
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <!-- info popup -->
    <vs-popup
      classContent="popup-example"
      title="ข้อมูลเพิ่มเติม"
      :active.sync="infoPopup"
    >
      <vs-row>
        <vs-col
          vs-w="12"
          vs-sm="2"
          vs-md="2"
          vs-lg="2"
          class="flex justify-center items-center"
        >
          เบอร์ติดต่อ
        </vs-col>
        <vs-col vs-w="10" vs-sm="8" vs-md="8" vs-lg="8">
          <vs-input
            class="w-full"
            v-model="wknOrder.orderDetail.customerPhone"
          />
        </vs-col>
        <vs-col vs-w="2" class="flex justify-end items-center">
          <vs-button color="success">
            <font-awesome-icon icon="user" style="color: #ffffff" />
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-row class="mt-4">
        <vs-col
          vs-w="12"
          vs-sm="2"
          vs-md="2"
          vs-lg="2"
          class="flex justify-center items-center"
        >
          ชื่อลูกค้า
        </vs-col>
        <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
          <vs-input
            class="w-full"
            v-model="wknOrder.orderDetail.customerName"
          />
        </vs-col>
      </vs-row>
      <vs-row class="mt-4">
        <vs-col
          vs-w="12"
          vs-sm="2"
          vs-md="2"
          vs-lg="2"
          class="flex justify-center items-center"
        >
          ที่อยู่
        </vs-col>
        <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
          <vs-input
            class="w-full"
            v-model="wknOrder.orderDetail.customerAddress"
          />
        </vs-col>
      </vs-row>
      <div :style="{ display: modalExpand }">
        <vs-row class="mt-4">
          <vs-col
            vs-w="12"
            vs-sm="2"
            vs-md="2"
            vs-lg="2"
            class="flex justify-center items-center"
          >
            Line ID
          </vs-col>
          <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
            <vs-input class="w-full" v-model="wknOrder.orderDetail.lineId" />
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col
            vs-w="12"
            vs-sm="2"
            vs-md="2"
            vs-lg="2"
            class="flex justify-center items-center"
          >
            Birthdate
          </vs-col>
          <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
            <v-date-picker
              v-model="wknOrder.orderDetail.birthDate"
              :locale="{
                id: 'th',
                firstDayOfWeek: 2,
                masks: { L: 'DD/MM/YYYY' }
              }"
            />
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col
            vs-w="12"
            vs-sm="2"
            vs-md="2"
            vs-lg="2"
            class="flex justify-center items-center"
          >
            รู้จักได้ยังไง
          </vs-col>
          <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
            <select
                v-model="wknOrder.orderDetail.howToKnowShop"
                class="w-full px-2 py-3 rounded"
                style="border: 1px solid #e0e0e0"
              >
                <option selected disabled>-- กรุณาเลือก --</option>
                <option value="1">เพื่อนแนะนำ</option>
                <option value="2">Google</option>
                <option value="3">Facebook</option>
              </select>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col
            vs-w="12"
            vs-sm="2"
            vs-md="2"
            vs-lg="2"
            class="flex justify-center items-center"
          >
            วันที่สมัคร
          </vs-col>
          <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
            {{ $dateFormat(wknOrder.orderDetail.createDateTime) }}
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="mt-4">
        <vs-col vs-w="12">
          <div class="flex justify-center items-center">
            <vs-button
              icon
              class="c-box-shadow"
              style="background-color: transparent !important; color: #cccccc"
              @click="modalExpandToggle"
            >
              <font-awesome-icon
                :icon="modalExpand == 'none' ? 'chevron-down' : 'chevron-up'"
                size="lg"
              />
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
      <div class="mt-4 mb-8">
        <hr />
      </div>
      <vs-row class="mt-4">
        <vs-col
          vs-w="12"
          vs-sm="2"
          vs-md="2"
          vs-lg="2"
          class="flex justify-center items-center"
        >
          วีธีชำระเงิน
        </vs-col>
        <vs-col vs-w="12" vs-sm="10" vs-md="10" vs-lg="10">
          <div class="flex justify-around items-center">
            <vs-button
                  border
                  class="ef-btn-payment"
                  :style="{
                    backgroundColor:
                      wknOrder.orderDetail.paymentMethod == 'เงินสด'
                        ? `#2196F3`
                        : `#ffffff !important`,
                    border: `1px solid #cccccc !important`,
                    color: `#cccccc`
                  }"
                  @click="onChangePaymentMethod('เงินสด')"
                  >เงินสด
                </vs-button>
                <vs-button
                  border
                  class="ef-btn-payment"
                  :style="{
                    backgroundColor:
                      wknOrder.orderDetail.paymentMethod == 'โอนเงิน'
                        ? `#2196F3`
                        : `#ffffff !important`,
                    border: `1px solid #cccccc !important`,
                    color: `#cccccc`
                  }"
                  @click="onChangePaymentMethod('โอนเงิน')"
                  >โอนเงิน
                </vs-button>
                <vs-button
                  border
                  class="ef-btn-payment"
                  :style="{
                    backgroundColor:
                      wknOrder.orderDetail.paymentMethod == 'บัตรเครดิต'
                        ? `#2196F3`
                        : `#ffffff !important`,
                    border: `1px solid #cccccc !important`,
                    color: `#cccccc`
                  }"
                  @click="onChangePaymentMethod('บัตรเครดิต')"
                  >บัตรเครดิต
                </vs-button>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="mt-8">
        <vs-col vs-w="10" class="text-right">
          <vs-button color="success" class="mr-4">บันทึกการเช่า</vs-button>
        </vs-col>
        <vs-col vs-w="2" class="text-right">
          <vs-button color="danger" @click="infoPopup = false"
            >ยกเลิก</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      calendarData: "",
      page: 1,
      max: 3,
      wknOrder: {
        orderDetail: {
          wknOrderId: null,
          wknOrderNumber: null,
          branchId: null,
          customerId: null,
          customerName: null,
          customerAddress: null,
          customerPhone: null,
          lineId: null,
          birthDate: null,
          howToKnowShop: null,
          accountNumber: null,
          accountName: null,
          bankName: null,
          startDate: null,
          endDate: null,
          paymentMethod: null,
          rentalPriceTotal: null,
          rentalDiscount: null,
          netPrice: null,
          bailTotal: null,
          bailDiscount: null,
          bailNetTotal: null,
          grandTotal: null,
          vat: null,
          beforeVat: null,
          inVat: null,
          vatSystem: null,
          taxRate: null,
          description: null,
          createDateTime: null,
          createBy: null,
          updateDateTime: null,
          updateBy: null,
          orderHeaderId: null,
          orderHeader: null
        },
        orderItems: []
      },
      saleItems: [
        {
          timestamp: {
            date: "2021-06-13",
            time: "18:00"
          },
          info: {
            found: true,
            name: "tester",
            address: "bkk",
            phoneNumber: "0912345678"
          },
          orderNumber: "WKN2104240001",
          branch: "สาขา 1",
          totalRentPrice: 1500,
          totalInsurancePrice: 2000,
          receiveDate: "2021-04-19",
          returnDate: "2021-04-30"
        },
        {
          timestamp: {
            date: "2021-06-13",
            time: "18:00"
          },
          info: {
            found: false,
            name: "",
            address: "",
            phoneNumber: "0912345678"
          },
          orderNumber: "WKN2104240002",
          branch: "สาขา 1",
          totalRentPrice: 1700,
          totalInsurancePrice: 2200,
          receiveDate: "2021-04-26",
          returnDate: "2021-04-30"
        }
      ],
      orders: [],
      infoPopup: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      filter: {
        date: null,
        keyword: null,
        color: null,
        size: null,
        type: null,
        texture: null,
        displayItems: [10, 50, 100],
        displayItemSelected: 10
      },
      localeStringConfig: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      paginationSelected: 1,
      options: ["item1", "item2", "item3"],
      knowSelected: 1,
      birthdate: "",
      modalExpand: "none"
    };
  },
  methods: {
    handlePrint() {
      this.$router.push({
        name: "sale-order-detail",
        params: {
          orderId: 1
        }
      });
    },
    modalExpandToggle() {
      if (this.modalExpand == "none") {
        this.modalExpand = "block";
      } else {
        this.modalExpand = "none";
      }
    },
    fetchOrder() {
      const state = this.$store.state;
      const request = this.$customAxios(
        "GET",
        `${state.apiURL}/api/v2/GetWknOrders`,
        {},
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res fetch orders`, res);
        this.orders = res;
      });
    },
    fetchOrderDetail(wknOrderId) {
      console.log(`>>> fetch order-detail`, wknOrderId);
      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/GetWknOrderDetail`,
        { wknOrderId: wknOrderId },
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res feth order-detail`, res);
        res.orderDetail.birthDate = new Date(res.orderDetail.birthDay)
        this.wknOrder = res
        this.infoPopup = true;
      });
    },
    onChangePaymentMethod(selectPayment) {
      this.wknOrder.orderDetail.paymentMethod = selectPayment;
    },
    removeOrder(wknOrderId) {
      console.log(`>>> remove order`, wknOrderId);
      const state = this.$store.state;
      const request = this.$customAxios(
        "POST",
        `${state.apiURL}/api/v2/CancelWknOrder`,
        { wknOrderId: wknOrderId },
        state.auth.apiHeader
      );

      request.then(res => {
        console.log(`>>> res remove order`, res);
        this.fetchOrder();
      });
    }
  },
  mounted() {
    console.log(`>>> mobile order mounted`);
    this.fetchOrder();
  }
};
</script>

<style>
.mobile-order-custom-date-picker {
  width: 100%;
  font-size: 14px;
  z-index: 999 !important;
}

.mobile-order-table th .vs-table-text {
  justify-content: start !important;
}

.cardx {
  box-shadow: none !important;
  border: 1px solid #f2f2f2;
}

.ef-btn-payment:active,
.ef-btn-payment:hover {
  box-shadow: none !important;
}

.c-box-shadow:hover {
  box-shadow: 2px 2px 2px 2px#f2f2f2 !important;
}
</style>
